body {
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  margin: 0;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
}

.btn {
  color: #fff;
  cursor: pointer;
  background-color: #8fb43a;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 45px;
  margin: 10px;
  transition: transform .25s ease-in-out;
  display: flex;
}

@media (width <= 350px) {
  .btn {
    width: 200px;
  }
}

.btn:hover {
  transform: scale(1.13);
}

.btn__actu {
  margin-left: 50px;
}

a {
  text-decoration: none;
}

nav {
  z-index: 10;
  opacity: .8;
  background-color: #4b5943;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 1.25rem;
  display: flex;
  position: fixed;
}

@media (width <= 950px) {
  nav {
    flex-direction: column;
    justify-content: space-around;
    height: 70px;
  }
}

@media (width <= 400px) {
  nav {
    height: 95px;
  }
}

nav .logo {
  justify-items: flex-start;
  margin-left: 1em;
}

nav a {
  color: #fff;
  cursor: pointer;
  margin-left: .5rem;
  margin-right: .5rem;
  text-decoration: none;
}

@media (width <= 400px) {
  nav a {
    font-weight: 400;
  }
}

.blocs {
  flex-flow: column wrap;
  margin: 0;
  display: flex;
}

.credit {
  z-index: 2;
  background-color: #fff;
  justify-content: flex-end;
  width: 100%;
  font-family: Fira Sans, sans-serif;
  display: flex;
}

.bloc {
  z-index: 2;
  background-color: #fff;
  flex-flow: column;
  align-items: center;
  width: 100%;
  height: 70vh;
  font-family: Fira Sans, sans-serif;
  display: flex;
  overflow-x: hidden;
}

.bloc__tcpo {
  color: #fff;
  z-index: 1;
  background-image: url("terrain1.fb5c28b3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  transition: filter 1s ease-in-out;
  top: 0;
  left: 0;
  overflow-y: hidden;
}

@media screen and (width <= 600px) {
  .bloc__tcpo {
    max-width: 160vw;
  }
}

.bloc__tcpo__title {
  flex-direction: column;
  align-items: center;
  padding-top: 13vh;
  display: flex;
}

@media (width <= 550px) {
  .bloc__tcpo__title {
    padding-top: 120px;
  }
}

.bloc__tcpo__title h1 {
  font-size: 5rem;
  font-weight: bold;
}

.bloc__tcpo__title h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

.bloc__actualites {
  flex-direction: row;
  height: 500px;
}

@media (width <= 1100px) {
  .bloc__actualites {
    flex-direction: column;
    height: fit-content;
  }
}

.bloc__actualites h1 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.bloc__actualites h3 {
  margin-top: 2rem;
}

.bloc__galerie {
  cursor: pointer;
  background-color: #8fb43a;
  background-image: url("animation1.7ab9aa30.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  height: 250px;
  display: flex;
}

.bloc__galerie p {
  color: #000;
  margin: 0;
  font-size: 5rem;
  font-weight: bold;
}

.bloc__installations {
  border-bottom: 8px solid #8fb43a;
  justify-content: space-evenly;
  height: 900px;
}

@media (width <= 700px) {
  .bloc__installations {
    height: 1100px;
  }
}

.bloc__ecole {
  flex-direction: row;
  justify-content: space-evenly;
  height: 500px;
}

@media (width <= 1525px) {
  .bloc__ecole {
    flex-direction: column;
    height: 900px;
  }
}

.bloc__animations {
  color: #fff;
  background-color: #8fb43a;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  height: 600px;
}

@media (width <= 1400px) {
  .bloc__animations {
    flex-direction: column;
    height: 1200px;
  }
}

.bloc__tournoi {
  background-color: #fff;
  justify-content: space-around;
  height: 1100px;
}

.bloc__tournoi__flex {
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  display: flex;
}

@media (width <= 1140px) {
  .bloc__tournoi__flex {
    flex-direction: column;
  }
}

@media (width <= 1500px) {
  .bloc__tournoi {
    height: 1100px;
  }
}

@media (width <= 1140px) {
  .bloc__tournoi {
    height: 1600px;
  }
}

.bloc__bureau {
  color: #fff;
  background-color: #8fb43a;
  justify-content: space-around;
  height: 170px;
}

@media (width <= 1000px) {
  .bloc__bureau {
    height: 220px;
  }
}

@media (width <= 720px) {
  .bloc__bureau {
    height: 250px;
  }
}

@media (width <= 520px) {
  .bloc__bureau {
    height: 330px;
  }
}

@media (width <= 350px) {
  .bloc__bureau {
    height: 390px;
  }
}

.bloc__inscription {
  border-bottom: 8px solid #8fb43a;
  flex-direction: row;
  justify-content: space-around;
  height: 500px;
}

@media (width <= 1400px) {
  .bloc__inscription {
    flex-direction: column;
    height: 800px;
  }
}

.bloc__partenariat {
  height: 400px;
}

.bloc__liens {
  border-top: 8px solid #8fb43a;
  flex-flow: wrap;
  justify-content: space-evenly;
  height: 150px;
}

.bloc__liens .btn {
  margin: 5px;
}

@media (width <= 520px) {
  .bloc__liens {
    flex-direction: column;
    height: 220px;
  }
}

.actu {
  flex-direction: column;
  flex: 5;
  align-items: center;
  width: 100%;
  height: 500px;
  display: flex;
}

.actu h1 {
  align-self: start;
  margin-left: 20px;
}

.actu__carrousel {
  width: 85%;
  height: 300px;
}

.actu__carrousel div {
  background-color: #fff;
  border: 5px solid #8fb43a;
  border-radius: 15px;
  align-items: flex-start;
  display: none;
}

.actu__carrousel div h3 {
  margin-left: 30px;
}

@media (width <= 260px) {
  .actu__carrousel div h3 {
    margin-left: 0;
  }
}

.actu__carrousel div.show {
  animation: 1.5s ease-in-out apparition;
  display: block;
}

.actu__radio {
  justify-content: space-evenly;
  width: 100%;
  display: flex;
}

.actu__radio input {
  cursor: pointer;
  display: none;
}

.actu__radio input:checked + label {
  background-color: #8fb43a;
}

.actu__radio label {
  cursor: pointer;
  border: 3px solid #8fb43a;
  border-radius: 100%;
  width: 25px;
  height: 25px;
}

@keyframes apparition {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.reservation {
  z-index: 2;
  background: #fff;
  border-left: 8px solid #8fb43a;
  flex-direction: column;
  flex: 3;
  align-items: center;
  height: 500px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

@media (width <= 1100px) {
  .reservation {
    border-top: 8px solid #8fb43a;
    border-left: none;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.border {
  border: 5px solid #8fb43a;
  border-radius: 15px;
  width: 300px;
  height: 120px;
}

.ext {
  border: 5px solid #4b5943;
}

.ext .btn {
  background-color: #4b5943;
}

.interne {
  color: #fff;
  background-color: #8fb43a;
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
  width: 60%;
  height: 80px;
  display: flex;
}

.interne .btn {
  color: #8fb43a;
  background-color: #fff;
}

.interne p {
  font-weight: 700;
}

@media (width <= 1500px) {
  .interne {
    border-radius: 20px;
  }
}

.trait {
  z-index: 2;
  background-color: #fff;
  width: 100%;
}

.trait div {
  background-color: #8fb43a;
  width: 8px;
  height: 12px;
  position: relative;
  left: 50vw;
}

.photosInstall {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
}

.photosInstall__1 {
  background-image: url("terrain2.bd7659e2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 550px;
  height: 450px;
  position: relative;
  right: 30px;
}

@media (width <= 1750px) {
  .photosInstall__1 {
    width: 450px;
    height: 350px;
  }
}

@media (width <= 1450px) {
  .photosInstall__1 {
    display: none;
  }
}

.photosInstall__1.rotate {
  animation: 1s ease-in-out forwards rotate2;
}

.photosInstall__2 {
  background-image: url("terrain1.fb5c28b3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 550px;
  height: 450px;
}

@media (width <= 1750px) {
  .photosInstall__2 {
    width: 450px;
    height: 350px;
  }
}

@media (width <= 1450px) {
  .photosInstall__2 {
    width: 550px;
    height: 450px;
  }
}

.photosInstall__3 {
  background-image: url("terrain3.c4ea7292.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 550px;
  height: 450px;
  position: relative;
  left: 30px;
}

@media (width <= 1750px) {
  .photosInstall__3 {
    width: 450px;
    height: 350px;
  }
}

@media (width <= 1450px) {
  .photosInstall__3 {
    display: none;
  }
}

.photosInstall__3.rotate {
  animation: 1s ease-in-out forwards rotate;
}

@keyframes rotate {
  0% {
    transform: rotate(0)translateX(0);
  }

  75% {
    transform: rotate(11deg)translateX(-27px);
  }

  100% {
    transform: rotate(10deg)translateX(-30px);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0)translateX(0);
  }

  75% {
    transform: rotate(-11deg)translateX(27px);
  }

  100% {
    transform: rotate(-10deg)translateX(30px);
  }
}

.photoTerrain {
  margin-top: 30px;
  overflow: hidden;
}

.photoTerrain img {
  max-width: 100%;
  height: auto;
  margin-top: -50px;
}

.photoEcole {
  opacity: 0;
  height: 450px;
}

.photoEcole img {
  width: auto;
  height: 100%;
}

.showPhoto {
  animation: 1.5s ease-in-out forwards apparition;
}

.photoInscription {
  opacity: 0;
  height: 450px;
}

.photoInscription img {
  width: auto;
  height: 100%;
}

.photoAnim {
  opacity: 0;
  height: 450px;
}

.photoAnim img {
  width: auto;
  height: 100%;
}

.reserve {
  justify-content: space-evenly;
  width: 100%;
  margin: 10px;
  display: flex;
}

.reserve div {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (width <= 700px) {
  .reserve {
    flex-direction: column;
    align-items: center;
    height: 300px;
  }
}

.finale {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.finale img {
  max-width: 600px;
  max-height: 500px;
}

.bureauList {
  flex-wrap: wrap;
  justify-content: space-evenly;
  display: flex;
}

.bureauList__1 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.bureauList__1 .photo {
  background-image: url("tennis1.143c133a.jpg");
  width: 85px;
  height: 100px;
}

.bureauList__2 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.bureauList__2 .photo {
  background-image: url("tennis1.143c133a.jpg");
  width: 85px;
  height: 100px;
}

.bureauList__3 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.bureauList__3 .photo {
  background-image: url("tennis1.143c133a.jpg");
  width: 85px;
  height: 100px;
}

.bureauList__4 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.bureauList__4 .photo {
  background-image: url("tennis1.143c133a.jpg");
  width: 85px;
  height: 100px;
}

.bureauList__5 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.bureauList__5 .photo {
  background-image: url("tennis1.143c133a.jpg");
  width: 85px;
  height: 100px;
}

.fete {
  background-color: #4b5943;
}

.overlay {
  z-index: 1;
  background-color: #000000b3;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.popup {
  z-index: 2;
  background-color: #fff;
  border-radius: 15px;
  flex-flow: column wrap;
  place-content: center;
  align-items: center;
  width: 400px;
  height: 400px;
  padding: 20px;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
}

.close-button {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.resa {
  z-index: 10;
  cursor: pointer;
  background-color: #8fb43a;
  background-image: url("logoTerrain2.cf137a57.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 100%;
  width: 75px;
  height: 75px;
  position: fixed;
  bottom: 40px;
  right: 35px;
}

@media (width <= 720px) {
  .resa {
    display: none;
  }
}

.resa:hover {
  transform: scale(1.13);
}

.gallery {
  flex-direction: column;
  align-items: flex-start;
  font-family: Fira Sans, sans-serif;
  display: flex;
}

.gallery__title {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.gallery__title btn {
  color: #fff;
}

.gallery__photo {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(25vw, 1fr));
  padding-left: 15px;
  padding-right: 15px;
  display: grid;
}

.gallery__photo img {
  width: 100%;
}

.btnRetour {
  cursor: pointer;
  background-color: #8fb43a;
  border-radius: 100%;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  text-decoration: none;
  transition: background-color .3s;
  display: inline-block;
}

.material-symbols-outlined {
  font-size: 1.25rem;
}

a {
  font-size: 1rem;
  font-weight: 600;
}

h1 {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 2.5rem;
}

h2 {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.5rem;
}

h3 {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.25rem;
}

p {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1rem;
}
/*# sourceMappingURL=index.d02fba25.css.map */
