$primary-color : #8fb43a;
$color2: #b7ce66;
$color3: #4b5943;


$font--400:1rem;
$font--500:1.25rem;
$font--600:1.5rem;
$font--700:2.5rem;
$font--xl:5rem;

@media(max-width: 720px){
    body{
        $font--400:0.8rem;
    $font--500:1rem;
    $font--600:1.3rem;
    $font--700:2rem;
    $font--xl:4rem;
    }
    
}


body {
    -webkit-text-size-adjust: none; /* Pour les navigateurs WebKit (Safari, Chrome) */
    -ms-text-size-adjust: none; /* Pour Internet Explorer */
    text-size-adjust: none; /* Pour les autres navigateurs */
    margin:0;
    overflow-x: hidden;
  }





::-webkit-scrollbar {
    width: 0px; /* Largeur de la barre de défilement */
  }

.btn{
    margin: 10px;
    @media  (max-width:350px){
        width: 200px;
    }
    background-color: $primary-color;
    width: 250px;
    height:45px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transition: transform 250ms ease-in-out;
    cursor: pointer;

    &:hover{
        transform: scale(1.13);
      }

      &__actu{
        margin-left: 50px;
      }
}

a{
    text-decoration: none;
}


nav{
    position: fixed;
    background-color: $color3;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    opacity: 0.8;
    font-size: $font--500;
    @media (max-width: 950px) {
        height: 70px;
        flex-direction: column;
        justify-content: space-around;
    }
    @media (max-width: 400px) {
        height: 95px;
    }


    .logo{
        justify-items: flex-start;
        margin-left: 1em;
    }
    a{
        margin-right: 0.5rem;
        margin-left:0.5rem;
        color: white;
        text-decoration: none;
        cursor: pointer;
        @media (max-width: 400px) {
        font-weight: 400;
        }
    }
}

$actu-height:500px;
.blocs{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
}

.credit{
    font-family: 'Fira Sans', sans-serif;
    background-color: white;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.bloc{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 70vh;
    font-family: 'Fira Sans', sans-serif;
    overflow-x: hidden;
    z-index: 2;
    background-color: white;
    &__tcpo{
        background-image: url("./images/terrain1.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
        height: 100vh;
        //position: sticky;
        overflow-y: hidden;
        top: 0;
        left: 0;
        z-index: 1;
        transition: filter 1s ease-in-out;
        @media screen and (max-width: 600px) {
            max-width: 160vw; // Nouvelle largeur maximale pour la version mobile
          }

        &__title{
            display:flex;
            flex-direction: column;
            align-items: center;
            padding-top: 13vh;


            @media (max-width: 550px) {
                padding-top: 120px;
            }
            h1{
                font-size: $font--xl;
                font-weight: bold;

            }
            h2{
                font-size: $font--700;
                font-weight: bold;
            }
            
        }
       
    }
    &__actualites{
        flex-direction: row;
        height: $actu-height;
        @media (max-width: 1100px) {
            flex-direction: column;
            height: fit-content;
        }
        h1{
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;
        }
        h3{
            margin-top: 2rem;
        }
    }
    &__galerie{
        height: 250px;
        display: flex;
        justify-content: center;
        background-image: url("./images/animation1.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-color: $primary-color;
        cursor: pointer;
        p{
            color: black;
            font-size: $font--xl;
            font-weight: bold;
            margin:0;
        }
    }
    &__installations{
        // background-color: $primary-color;
        height: 900px;
        // color: white;
        justify-content: space-evenly;
        border-bottom: 8px solid $primary-color;
        @media (max-width: 700px) {
            height: 1100px;
        }
    }
    &__ecole{
        height: 500px;
        flex-direction: row;
        justify-content: space-evenly;
        @media (max-width: 1525px) {
            height: 900px;
            flex-direction: column;
        }
    }
    &__animations{
        height: 600px;
        flex-direction: row-reverse;
        justify-content: space-evenly;
        background-color: $primary-color;
        color: white;
        @media (max-width: 1400px) {
            height: 1200px;
            flex-direction: column;
        }
    }
    &__tournoi{
        height: 1100px;
        background-color: white;
        justify-content: space-around;
        
        &__flex{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around; 
            @media (max-width: 1140px) {
                flex-direction: column;
            }
        }
        @media (max-width: 1500px) {
            height: 1100px;
        }
        @media (max-width: 1140px) {
            height: 1600px;
        }

    }
    &__bureau{
        height: 170px;
        justify-content: space-around;
        background-color: $primary-color;
        color: white;
        @media (max-width: 1000px) {
            height: 220px;
        }
        @media (max-width: 720px) {
            height: 250px;
        }
        @media (max-width: 520px) {
            height: 330px;
        }
        @media (max-width: 350px) {
            height: 390px;
        }

    }
    &__inscription{
        height: 500px;
        // background-color: $primary-color;
        border-bottom: 8px solid $primary-color;
        flex-direction: row;
        justify-content: space-around;
        @media (max-width: 1400px) {
            height: 800px;
            flex-direction: column;
        }
    }
    &__partenariat{
        height: 400px;
    }
    &__liens{
        border-top: 8px solid $primary-color;
        height: 150px;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        .btn{
            margin: 5px;
        }
        @media (max-width: 520px) {
            height: 220px;
            flex-direction: column;
        }
    }
}

.actu{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 500px;
    flex:5;
    h1{
        align-self: start;
        margin-left: 20px;
    }


    &__carrousel{
        height: 300px;
        width: 85%;
        div{
            //width: 100vw;
            //mettre animation
            display: flex;
            align-items: flex-start;
            background-color: white;
            display: none;
            border:  5px solid $primary-color;
            border-radius: 15px;
            h3{
                margin-left: 30px;
                @media (max-width:260px){
                    margin-left: 0px;
            }
            }
            &.show{
                animation: apparition 1500ms ease-in-out ;
                display: block;
            }
        }  
    } 
    &__radio{
        width: 100%;
        display:flex;
        justify-content: space-evenly;
        input{
            display: none;
            &:checked + label {
                background-color: $primary-color;
            }
            cursor: pointer;
        }
        label{
            height: 25px;
            width: 25px;
            border-radius: 100%;
            border: 3px solid $primary-color;
            cursor: pointer;
        }

    }   
}

@keyframes apparition{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.reservation{
    flex: 3;
    border-left:  8px solid $primary-color;
    height: $actu-height;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 2;
    @media (max-width: 1100px) {
        margin-top: 30px;
        border-left: none;
        border-top:  8px solid $primary-color;
        width: 100%;
        margin-bottom: 30px;

    }
}

.border{
    width: 300px;
    height: 120px;
    border:  5px solid $primary-color;
    border-radius: 15px;
}

.ext{
    border:  5px solid $color3;
    .btn{
        background-color: $color3;
    }
}

.interne{
    width: 60%;
    height: 80px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: space-around;
    color: white;
    .btn{
        background-color: white;
        color:$primary-color; 
    }

    p{
        font-weight: 700;
    }

    @media (max-width: 1500px) {
       
        border-radius: 20px;

    }
}
.trait{
    background-color: white;
    z-index: 2;
    width: 100%;
    div{
        position: relative;
        left: 50vw;
        width: 8px;
        height: 12px;
        background-color: $primary-color; 
    }
    
}
//Mettre en wrap pour enlever la scrollbar du bas
.photosInstall{
    display: flex;
    margin-top: 1rem;
    margin-bottom: 2rem;

    &__1{
        position: relative;
        width: 550px;
        height: 450px;
        background-image: url("./images/terrain2.jpg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        right:30px;

        @media (max-width: 1750px) {
            width: 450px;
            height: 350px;
        }
       
        @media (max-width: 1450px) {
            display: none;
           
        }
        &.rotate{
            animation: rotate2 1s ease-in-out forwards;    
        }
    }
    &__2{
        width: 550px;
        height: 450px;
        background-image: url("./images/terrain1.jpg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 1750px) {
            width: 450px;
            height: 350px;
        }

        @media (max-width: 1450px) {
            width: 550px;
            height: 450px; 

        }

    }
    &__3{
        position: relative;
        width: 550px;
        height: 450px;
        background-image: url("./images/terrain3.jpg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        left:30px;

        @media (max-width: 1750px) {
            width: 450px;
            height: 350px;
        }
        @media (max-width: 1450px) {
            display: none;
        }
        &.rotate{
            animation: rotate  1s ease-in-out forwards;    
        }
    }
}
$degre-rotation:10;
@keyframes rotate {
0% {
    transform: rotate(0deg) translateX(0px);
}
75% {
    transform: rotate(11deg) translateX(-27px);
}
100%{
    transform: rotate(10deg) translateX(-30px);
}}


@keyframes rotate2 {
    0% {
        transform: rotate(0deg) translateX(0px);
    }
    75% {
        transform: rotate(-11deg) translateX(27px);
    }
    100%{
        transform: rotate(-10deg) translateX(30px);
    }}

.photoTerrain{
    //width: 350px;
   //height: 200px;
    overflow: hidden;
    margin-top: 30px;   
    img{
    max-width: 100%;
    height: auto;
    margin-top: -50px
    }
}

.photoEcole{

    height: 450px;
    opacity: 0;
    img{
        height: 100%;
        width: auto;
        }
}

.showPhoto{
    animation: apparition 1500ms ease-in-out forwards;
}
    
.photoInscription{

    height: 450px;
    opacity: 0;
    img{
        height: 100%;
        width: auto;
        }
}

.photoAnim{
    height: 450px;
    opacity: 0;

    img{
        height: 100%;
        width: auto;
        }

}

.reserve{
    
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
    div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        height: 300px;
    }
   
}

.finale{
    display:flex;
    flex-direction: column;
    align-items: center;

    img{
        max-width: 600px;
        max-height: 500px;
    }
}

@mixin membreBureau(){
    display:flex;
    flex-direction: column;
    align-items: center;
    .photo{
        width: 85px;
        height: 100px;
        background-image: url("./images/tennis1.jpg");
    }  
}

.bureauList{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    &__1{
        @include membreBureau();
    }
    &__2{
        @include membreBureau();
    }
    &__3{
        @include membreBureau();
    }
    &__4{
        @include membreBureau();
    }
    &__5{
        @include membreBureau();
    }
}

.fete{
    background-color: #4b5943;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    display: none; /* la div est cachée par défaut */
  }
  
  .popup {
    display: none; //change to flex when open
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 2;
    width: 400px;
    height: 400px;
    overflow: auto;
    border-radius: 15px;
  }
  
  /* Style pour le bouton de fermeture */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .resa{
    background-color: $primary-color;
    position: fixed;
    height: 75px;
    width: 75px;
    bottom: 40px;
    right: 35px;
    z-index: 10;
    border-radius:100% 100% 100% 100% / 100% 100% 100% 100%;
    background-image: url("./images/logoTerrain2.jpg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 720px) {
        display: none;
    }



    cursor: pointer;
    &:hover{
        transform: scale(1.13);
      }
  }


  .gallery{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Fira Sans', sans-serif;
    &__title{
        display: flex;
        flex-direction: row;
        align-items: center;
        btn{
            color:white;
        }
    }
    &__photo{
        padding-left: 15px;
        padding-right: 15px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(25vw, 1fr));
        grid-gap: 15px;
        img{
            width: 100%;
            //height: auto;
        }
    }
  }


  .btnRetour {
    display: inline-block;
    padding: 12px 12px;
    background-color: $primary-color;
    text-decoration: none;
    border-radius: 100%;
    transition: background-color 0.3s ease;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
  }
  

  .material-symbols-outlined {
    font-size: $font--500;
  }




a{
    font-size: $font--400;
    font-weight: 600;
}

h1{
    font-size: $font--700;
    margin-left: 10px;
    margin-right: 10px;
}

h2{
    font-size: $font--600;
    margin-left: 10px;
    margin-right: 10px;
}

h3{
    font-size: $font--500;
    margin-left: 10px;
    margin-right: 10px;
}

p{
    font-size: $font--400;
    margin-left: 10px;
    margin-right: 10px;
}
